import React, { FunctionComponent } from 'react'
import config from "../config";
import DefaultHomePage from "./home-screens/DefaultHomePage";
import MastercardHomePage from "./home-screens/MastercardHomePage";

interface HomePageProps {
    onClickEnterMetaverse: Function
}

const HomePage : FunctionComponent<HomePageProps> = (props) => {

    const {onClickEnterMetaverse } = props;

    switch (config.landingPage.variant){
        case "mastercard": {
            return <MastercardHomePage onInteracted={onClickEnterMetaverse} />
        }
        default: {
            return <DefaultHomePage onInteracted={onClickEnterMetaverse} />
        }
    }
}

export default HomePage;