import * as THREE from "three";
import Hoverable from "./Hoverable";
import MultiplayerSimple  from "./MultiplayerSimple.ts";
import Component from "./Component";
import App from "../core/App";
import AgoraRTC from "agora-rtc-sdk-ng";
import BelongService from "../core/BelongService";
import {BelongData} from "./BelongWelcome";
import {WorldMap} from "../utilities/WorldMap";

export default class BelongInteractiveMap extends Component
{
    raycaster = new THREE.Raycaster();
    width = 3;
    height = 3;
    heightmap = null;
    inRange = true;
    activeDistance = 15;

    belongService = new BelongService();
    data = [];
    content = [];
    painter = setInterval(() => null, 50);
    optionSelected = "";

    constructor({
                    uuid,
                    scene,
                    images,
                    camera,
                    renderer,
                    width,
                    height,
                    activeDistance
                })
    {
        super();

        this.uuid = uuid;
        this.activeDistance = activeDistance;

        const root = document.getElementById("root");
        const canvas = document.createElement("canvas");
        canvas.style.display = "none";
        this.canvas = canvas;
        root.appendChild(canvas);

        canvas.id = uuid;
        this.ctx = canvas.getContext("2d");
        this.ctx.canvas.width = width * 1000;
        this.ctx.canvas.height = height * 1000;
        this.ctx.fillStyle = "#FFF";
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);

        this.ctx.font = "150px Mastercard";

        this.scene = scene;
        this.images = images;
        this.camera = camera;
        this.renderer = renderer;

        this.width = width || 3;
        this.height = height || 3;

        this.loader = new THREE.TextureLoader();

        this.geometry = new THREE.PlaneBufferGeometry(this.width * .96, this.height);
        const texture = new THREE.CanvasTexture(this.ctx.canvas);
        this.texture = texture;
        this.material = new THREE.MeshBasicMaterial({ map: texture });

        this.setupBillboard();

        this.board = new THREE.Mesh(this.geometry, this.material);
        scene.add(this.board);

        new Hoverable(this.board, (e) =>
        {
            document.body.style.cursor = "pointer";
        });

        this.renderer.domElement.addEventListener("pointerdown", (e) =>
        {
            const point = this.getMousePoint(e);
            console.log(point);

            if(point) {
                const normX = this.getNormalisedX(point.x);
                const normY = this.getNormalisedY(point.y);

                if(normX !== -1 && normY !== -1) {
                    if(normX === 0 && normY <= 27) {
                        this.option = BelongData.discoveryOptions[normY];
                    } else if(normX === 1 && normY <= 26) {
                        this.option = BelongData.discoveryOptions[BelongData.discoveryOptions.length - 1 - normY];
                    }

                    if(this.option) this.selectOption(normX, normY, this.option);
                }
            }
        });
    }

    getMouseX = (event) =>
    {
        return (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
    };

    getMouseY = (event) =>
    {
        return -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
    };

    setupBillboard = () =>
    {
        let count = 0;
        let options = BelongData.discoveryOptions;
        this.ctx.textAlign = "center";
        this.drawMap();

        for(let i = 0; i < 28; i++) {
            let currentText = options[i];

            if(currentText === this.option) {
                this.setSelectedStyle();
            } else {
                this.setDefaultStyle();
            }

            this.ctx.fillText(currentText, this.ctx.canvas.width * 0.1, this.ctx.canvas.height * 0.03 + count * 220);

            if(i !== options.length - 1 - i) {
                currentText = options[options.length - 1 - i];
                if(currentText === this.option) {
                    this.setSelectedStyle();
                } else {
                    this.setDefaultStyle();
                }

                this.ctx.fillText(currentText, this.ctx.canvas.width * 0.9, this.ctx.canvas.height * 0.03 + count * 220);
            }

            count++;
        }

        if(this.option) {
            this.belongService.getCountriesByReflectionAsync(this.option)
                .then(countries => {
                    for(const c of countries) {
                        const countryMap = BelongData.countriesMap[c.location];

                        this.drawIndicator(countryMap.x, countryMap.y, c.count);
                    }
                })
        }

        this.texture.needsUpdate = true;
    };

    drawMap = () =>
    {
        const ctx = this.ctx;
        const texture = this.texture;
        const img = new window.Image();

        img.addEventListener("load", () =>
        {
            ctx.drawImage(img,
                ctx.canvas.width * 0.2,
                ctx.canvas.height * 0.1,
                ctx.canvas.width * 0.6,
                ctx.canvas.width * 0.6 * 0.75);
            texture.needsUpdate = true;
        });

        img.setAttribute("src", WorldMap);
    };

    drawIndicator = (x, y, count) => {
        const ctx = this.ctx;
        const normalisedX = ((x + 2.66) / 5.32) * ctx.canvas.width;
        const normalisedY = (1 - ((y + 1.62) / 3.24)) * ctx.canvas.height;

        ctx.fillStyle = "#000";
        ctx.strokeStyle = "#F00";
        ctx.lineWidth = 30;

        ctx.beginPath();
        ctx.arc(normalisedX, normalisedY, 70, 0, 2 * Math.PI); // TODO: Bigger blobs, circles -> increase this proportionally to font
        ctx.stroke();
        ctx.fill();
        ctx.closePath();

        ctx.fillStyle = "#FFF";
        ctx.font = "80px MastercardBold"; // TODO: Bigger blobs, circles -> increase this
        ctx.textAlign = "center";
        ctx.fillText(count, normalisedX, normalisedY + 28); // TODO: Bigger blobs, circles -> increase this proportionally to font
        this.texture.needsUpdate = true;
    }

    setDefaultStyle = () => {
        this.ctx.fillStyle = "#000";
        this.ctx.font = "150px Mastercard";
    }

    setSelectedStyle = () => {
        this.ctx.fillStyle = "#000";
        this.ctx.font = "150px MastercardBold";
    }

    selectOption = () => {
        this.removeContent();
        this.setupBillboard();
    }

    removeContent = () =>
    {
        const canvas = this.ctx.canvas;

        this.ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.ctx.fillStyle = "#FFF";
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);

    };

    getMousePoint = (e) =>
    {
        let x = this.getMouseX(e);
        let y = this.getMouseY(e);

        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        const intersects = this.raycaster.intersectObject(this.board);

        if (intersects.length === 0)
        {
            return;
        }

        const intersection = intersects[0];

        if (intersection && intersection.uv && intersection.distance < this.activeDistance)
        {
            return {
                x: intersects[0].uv.x * this.width - this.width / 2,
                y: intersects[0].uv.y * this.height - this.height / 2,
                uvx: intersects[0].uv.x,
                uvy: intersects[0].uv.y
            };
        }
    };

    setPosition = (x, y, z) =>
    {
        this.board.position.set(x, y, z);
    };

    setRotation = (x, y, z) =>
    {
        this.board.rotateY(y * THREE.MathUtils.DEG2RAD);
    };

    rotateY(angle)
    {
        this.board.rotateY(angle);
    }

    getNormalisedX = (value) =>
    {
        if(value < -2.8) {
            return 0;
        } else if(value >= 2.8) {
            return 1;
        }

        return -1;
    }

    getNormalisedY = (value) =>
    {
        let count = 0;

        for(let i = 3.22; i >= -3.22; i -= 0.22) {
            if(value <= i && value > i - 0.22) {
                return count;
            } else {
                count++;
            }
        }

        return -1;
    }

    setupHeightMap(heightmap)
    {
        if (heightmap)
        {
            this.heightmap = heightmap;
            return true;
        }

        return false;
    }
}
