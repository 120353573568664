import React, { FunctionComponent } from 'react'
import UIAppBar from "../../components/UIAppBar";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ImageMap } from "@qiuz/react-image-map";

interface MastercardHomePageProps {
    onInteracted: Function
}

const mapArea = [
    {
        "id": "ZOOM",
        "width": "21.4%",
        "height": "39.5%",
        "left": "8.5%",
        "top": "12.0%",
        "href": "101"
    },
    {
        "id": "METAVERSE",
        "width": "21.4%",
        "height": "39.5%",
        "left": "70.1%",
        "top": "12.2%",
        "href": "102"
    },
    {
        "id": "CHAT",
        "width": "11.5%",
        "height": "15.0%",
        "left": "22.0%",
        "top": "80.5%",
        "href": "103"
    },
]

const MastercardHomePage : FunctionComponent<MastercardHomePageProps> = (props) => {

    const onMapClick = async (area: any) => {
        if (area.id)
        {
            switch (area.id)
            {
                case "ZOOM":
                {
                    window.open('https://mastercard.zoom.us/j/00000000000', '_blank')
                    break;
                }
                case 'METAVERSE': {
                    props.onInteracted();
                    break;

                }
                case 'CHAT': {
                    // todo - add chat connection
                    // props.onInteracted();
                    break;

                }
            }
        }
    }

    return (

        <>
            <div className="click-region-outer">
                <div className="click-region-inner">
                    <ImageMap
                        className="click-region"
                        src="/assets/images/mastercardSplash/mastercardSplash_BG.jpg"
                        map={mapArea}
                        //commented out for holding
                        onMapClick={onMapClick}
                        //added for holding
                        // style={{
                        //     pointerEvents: "none"
                        // }}
                    />
                    <img
                        className="click-region"
                        src="/assets/images/mastercardSplash/mastercardSplash_FG.png"
                        width="100%"
                        alt=''
                        style={{
                            zIndex: 1000,
                            pointerEvents: "none"
                        }}
                    />
                </div>
            </div>
            <UIAppBar/>
        </>
    );
}

export default withAuthenticationRequired(MastercardHomePage);

