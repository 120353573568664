import * as THREE from "three";
import Hoverable from "./Hoverable";
import MultiplayerSimple  from "./MultiplayerSimple.ts";
import Component from "./Component";
import App from "../core/App";
import AgoraRTC from "agora-rtc-sdk-ng";
import BelongService from "../core/BelongService";

export default class BelongBillboard extends Component
{
    raycaster = new THREE.Raycaster();
    width = 3;
    height = 3;
    heightmap = null;
    inRange = true;
    activeDistance = 15;

    belongService = new BelongService();
    data = [];
    content = [];
    painter = setInterval(() => null, 50);

    constructor({
                    uuid,
                    scene,
                    images,
                    camera,
                    renderer,
                    width,
                    height,
                    activeDistance
                })
    {
        super();

        this.uuid = uuid;
        this.activeDistance = activeDistance;

        const root = document.getElementById("root");
        const canvas = document.createElement("canvas");
        canvas.style.display = "none";
        this.canvas = canvas;
        root.appendChild(canvas);

        canvas.id = uuid;
        this.ctx = canvas.getContext("2d");
        this.ctx.canvas.width = width * 1000;
        this.ctx.canvas.height = height * 1000;
        this.ctx.fillStyle = "#000";
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);

        this.scene = scene;
        this.images = images;
        this.camera = camera;
        this.renderer = renderer;

        this.width = width || 3;
        this.height = height || 3;

        this.loader = new THREE.TextureLoader();

        this.setupBillboard();

        this.distanceVerify = setInterval(() =>
        {
            if (this.heightmap && this.heightmap.playerPos)
            {
                const distance = Math.sqrt(Math.pow(this.board.position.x - this.heightmap.playerPos.x, 2) + Math.pow((this.board.position.z * -1) - this.heightmap.playerPos.y, 2));

                if (distance > this.activeDistance && this.inRange)
                {
                    clearInterval(this.painter);
                    this.inRange = false;
                } else if (distance <= this.activeDistance && !this.inRange)
                {
                    this.setupBillboard();
                    this.inRange = true;
                }
            }
        }, 250);

        this.geometry = new THREE.PlaneBufferGeometry(this.width * .96, this.height);
        const texture = new THREE.CanvasTexture(this.ctx.canvas);
        this.texture = texture;
        this.material = new THREE.MeshBasicMaterial({ map: texture });

        this.board = new THREE.Mesh(this.geometry, this.material);
        scene.add(this.board);

        new Hoverable(this.board, (e) =>
        {
            document.body.style.cursor = "pointer";
        });

        this.renderer.domElement.addEventListener("pointerdown", (e) =>
        {
            const point = this.getMousePoint(e);
            console.log(point);
        });
    }

    getMouseX = (event) =>
    {
        return (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
    };

    getMouseY = (event) =>
    {
        return -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
    };

    setupBillboard = () =>
    {
        this.removeContent();
        this.getData();

        this.painter = setInterval(() =>
        {
            this.removeContent();
            const overlap = [];

            this.ctx.fillStyle = "#FF4500";
            this.ctx.font = "160px Mastercard";
            for (let i = 0; i < this.content.length; i++)
            {
                const element = this.content[i];
                this.ctx.textAlign = element.align;
                if(element.y < this.ctx.canvas.height + 360) this.ctx.fillText(element.content, element.x, element.y);
                element.y -= 16;

                if (element.y <= -360)
                {
                    element.y = this.content[this.content.length - 1].y + (element.align === "left" ? 360 : 200);
                    overlap.push({ index: i, element: element });
                }
            }

            console.log(this.content.length);

            for (const e of overlap)
            {
                this.content.splice(e.index, 1);
                this.content.push(e.element);
            }

            this.texture.needsUpdate = true;
        }, 75);
    };

    getData = () =>
    {
        this.ctx.font = "160px Mastercard";

        if (this.data.length === 0)
        {
            this.belongService.getReflectionsAsync().then((res) => {
                this.data = res;

                for (const e of this.data)
                {
                    if(e.trim() === '')
                        continue;

                    const lines = this.getLines(e, 0.85 * this.ctx.canvas.width);

                    if (lines.length === 1)
                    {
                        const entry = {};
                        entry.align = "left";
                        entry.x = 0.025 * this.ctx.canvas.width;
                        entry.y = (this.content.length === 0 ? 0.05 * this.ctx.canvas.height : this.content[this.content.length - 1].y + 360);
                        entry.content = `"${lines[0]}"`;
                        this.content.push(entry);
                    } else if (lines.length === 2)
                    {
                        const entry = {};
                        entry.align = "left";
                        entry.x = 0.025 * this.ctx.canvas.width;
                        entry.y = (this.content.length === 0 ? 0.05 * this.ctx.canvas.height : this.content[this.content.length - 1].y + 360);
                        entry.content = "\"" + lines[0];
                        this.content.push(entry);
                        const entry2 = {};
                        entry2.align = "right";
                        entry2.x = 0.975 * this.ctx.canvas.width;
                        entry2.y = (this.content.length === 0 ? 0.05 * this.ctx.canvas.height : entry.y + 200);
                        entry2.content = lines[1] + "\"";
                        this.content.push(entry2);
                    }
                }
            })
        } else
        {
            // TODO: Change to `this.belongService.getReflectionsAsync();` once tested
            // TODO: This is only POC that it works when data changes

            this.belongService.getReflectionsAsync().then((res) => {
                const newData = res;
                let difference = newData.filter(x => !this.data.includes(x));

                for (const e of difference)
                {
                    const lines = this.getLines(e, 0.85 * this.ctx.canvas.width);

                    if (lines.length === 1)
                    {
                        const entry = {};
                        entry.align = "left";
                        entry.x = 0.025 * this.ctx.canvas.width;
                        entry.y = (this.content.length === 0 ? 0.05 * this.ctx.canvas.height : this.content[this.content.length - 1].y + 360);
                        entry.content = `"${lines[0]}"`;
                        this.content.push(entry);
                    } else if (lines.length === 2)
                    {
                        const entry = {};
                        entry.align = "left";
                        entry.x = 0.025 * this.ctx.canvas.width;
                        entry.y = (this.content.length === 0 ? 0.05 * this.ctx.canvas.height : this.content[this.content.length - 1].y + 360);
                        entry.content = "\"" + lines[0];
                        this.content.push(entry);
                        const entry2 = {};
                        entry2.align = "right";
                        entry2.x = 0.975 * this.ctx.canvas.width;
                        entry2.y = (this.content.length === 0 ? 0.05 * this.ctx.canvas.height : entry.y + 200);
                        entry2.content = lines[1] + "\"";
                        this.content.push(entry2);
                    }
                }

                this.data = [...newData];
            });
        }
    };

    getLines = (text, maxWidth) =>
    {
        let words = text.split(" ");
        let lines = [];
        let currentLine = words[0];

        this.ctx.font = "160px Mastercard";
        for (let i = 1; i < words.length; i++)
        {
            const word = words[i];

            const width = this.ctx.measureText(currentLine + " " + word).width * 1.1;

            if (width < maxWidth)
            {
                currentLine += " " + word;
            } else
            {
                lines.push(currentLine);
                currentLine = word;
            }
        }

        lines.push(currentLine);
        return lines;
    };

    removeContent = () =>
    {
        const canvas = this.ctx.canvas;

        this.ctx.clearRect(0, 0, canvas.width, canvas.height);
        this.ctx.fillStyle = "#000";
        this.ctx.fillRect(0, 0, canvas.width, canvas.height);

    };

    getMousePoint = (e) =>
    {
        let x = this.getMouseX(e);
        let y = this.getMouseY(e);

        this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera);
        const intersects = this.raycaster.intersectObject(this.board);

        if (intersects.length === 0)
        {
            return;
        }

        const intersection = intersects[0];

        if (intersection && intersection.uv && intersection.distance < this.activeDistance)
        {
            return {
                x: intersects[0].uv.x * this.width - this.width / 2,
                y: intersects[0].uv.y * this.height - this.height / 2,
                uvx: intersects[0].uv.x,
                uvy: intersects[0].uv.y
            };
        }
    };

    setPosition = (x, y, z) =>
    {
        this.board.position.set(x, y, z);
    };

    setRotation = (x, y, z) =>
    {
        this.board.rotateY(y * THREE.MathUtils.DEG2RAD);
    };

    rotateY(angle)
    {
        this.board.rotateY(angle);
    }

    setupHeightMap(heightmap)
    {
        if (heightmap)
        {
            this.heightmap = heightmap;
            return true;
        }

        return false;
    }
}
