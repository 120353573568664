import { Box, IconButton, MenuItem, TextField } from "@mui/material";
import List from "@mui/material/List";
import DialogScheduleMeeting from "./DialogScheduleMeeting";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Camera, MeetingRoom, Schedule } from "@material-ui/icons";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import { Login, Logout } from "@mui/icons-material";
import React, { useState } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from "@mui/icons-material/Menu";
import QuizIcon from "@mui/icons-material/Quiz";
import { ROOMS } from "../core/Scene";
import { observer } from "mobx-react";
import config from "../config";

const Drawer = observer((props) => {
  const { isAuthenticated, logout, loginWithRedirect, user } = useAuth0();

  const [open, setOpen] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState(false);
  const { engine } = props;

  return (
    <>
      <Box position="absolute" left={0} top={0} p={2} zIndex={100}>
        <IconButton style={{ backgroundColor: "#121331", color: "white" }} onClick={() => setOpen(true)} size="large">
          <MenuIcon />
        </IconButton>
      </Box>
      <MuiDrawer open={open} onClose={() => setOpen(false)}>
        <Box minWidth={300}>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem button onClick={() => window.open("/", "_self")}>
              <ListItemIcon>
                <MeetingRoom />
              </ListItemIcon>
              <ListItemText primary="Return to Start Menu " />
            </ListItem>
            {isAuthenticated && (
              <>
                <DialogScheduleMeeting open={scheduleOpen} onClose={() => setScheduleOpen(false)} />
                <ListItem
                  // button
                  // disabled={!user?.["https://zonevs.io/user_metadata"]?.canStartMeetings}
                  onClick={() => setScheduleOpen(true)}
                >
                  <ListItemIcon>
                    <Schedule />
                  </ListItemIcon>
                  <ListItemText primary="Schedule Meeting" />
                </ListItem>

                <ListItem button onClick={() => logout({ returnTo: window.location.href })}>
                  <ListItemIcon>
                    <Logout />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </>
            )}
            {/*{!isAuthenticated && (*/}
            {/*  <ListItem button onClick={loginWithRedirect}>*/}
            {/*    <ListItemIcon>*/}
            {/*      <Login />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Administrator Login" />*/}
            {/*  </ListItem>*/}
            {/*)}*/}
            <Divider />
            {/*<ListItem button onClick={() => setDebugOpen(!debugOpen)}>
                            <ListItemIcon>
                                <BugReport />
                            </ListItemIcon>
                            <ListItemText primary="Debug Mode" />
                        </ListItem>*/}
            {/*<ListItem*/}
            {/*    button*/}
            {/*    onClick={() => window.toggleGreenScreenMode()}*/}
            {/*>*/}
            {/*    <ListItemIcon>*/}
            {/*        <Camera />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Toggle Greenscreen Presentation" />*/}
            {/*</ListItem>*/}
            {/*<Divider />*/}

            {/*<ListItem*/}
            {/*    button*/}
            {/*    onClick={() => window.hideVideoViewerGreenscreen()}*/}
            {/*>*/}
            {/*    <ListItemIcon>*/}
            {/*        <Camera />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Hide Greenscreen Videos" />*/}
            {/*</ListItem>*/}
            {/*<Divider />*/}

            {/*<ListItem*/}
            {/*    button*/}
            {/*    onClick={() => window.showVideoViewerGreenscreen()}*/}
            {/*>*/}
            {/*    <ListItemIcon>*/}
            {/*        <Camera />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Show Greenscreen Videos" />*/}
            {/*</ListItem>*/}
            {/*<Divider />*/}
            {/*/////////////////////////////////*/}

            {/*/////////////////////*/}
          </List>
          {isAuthenticated && engine.hosting && (
            <TextField
              label="Switch scene"
              fullWidth
              select
              onChange={(event) => engine.switchScene(event.target.value, true)}
            >
              {ROOMS.map((room) => (
                <MenuItem key={room.id} value={room.id}>
                  {room.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          {!engine.multiplayer.joined && !config.app.disableZoneJump && (
            <List component="nav" aria-label="list only if auth">
              {/*<ListItem button onClick={() => window.open("./?sceneOverride=classic", "_self")}>*/}
              {/*  <ListItemIcon>*/}
              {/*    <MeetingRoom />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="Jump to Corporate Room" />*/}
              {/*  <img src="assets/images/roomThumbs/corporateRoom.jpg" alt={""} />*/}
              {/*</ListItem>*/}
              {/*<Divider />*/}

              {/*<ListItem button onClick={() => window.open("./?sceneOverride=exhibitionSpace", "_self")}>*/}
              {/*  <ListItemIcon>*/}
              {/*    <MeetingRoom />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="Jump to Exhibition Space" />*/}
              {/*  <img src="assets/images/roomThumbs/exhibitionSpace.jpg" alt={""} />*/}
              {/*</ListItem>*/}
              {/*<Divider />*/}

              {/*<ListItem button onClick={() => window.open("./?sceneOverride=conferenceRoom", "_self")}>*/}
              {/*  <ListItemIcon>*/}
              {/*    <MeetingRoom />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="Jump to Conference Room" />*/}
              {/*  <img src="assets/images/roomThumbs/conferenceRoom.jpg" alt={""} />*/}
              {/*</ListItem>*/}
              {/*<Divider />*/}

              {/*<ListItem*/}
              {/*    button*/}
              {/*    onClick={() => window.open("./?sceneOverride=natureLounge", "_self") }*/}
              {/*>*/}
              {/*    <ListItemIcon>*/}
              {/*        <MeetingRoom />*/}
              {/*    </ListItemIcon>*/}
              {/*    <ListItemText primary="Jump to Nature Lounge" />*/}
              {/*    <img src="assets/images/roomThumbs/natureLounge.jpg" alt={""}/>*/}
              {/*</ListItem>*/}
              {/*<Divider />*/}

              {/*<ListItem*/}
              {/*    button*/}
              {/*    onClick={() => window.open("./?sceneOverride=syngentaPitchRoom", "_self") }*/}
              {/*>*/}
              {/*    <ListItemIcon>*/}
              {/*        <MeetingRoom />*/}
              {/*    </ListItemIcon>*/}
              {/*    <ListItemText primary="Jump to Syngenta Room" />*/}
              {/*    <img src="assets/images/roomThumbs/null.jpg" alt={""}/>*/}
              {/*</ListItem>*/}
              {/*<Divider />*/}

              {/*<ListItem button onClick={() => window.open("./?sceneOverride=festiverse", "_self")}>*/}
              {/*  <ListItemIcon>*/}
              {/*    <MeetingRoom />*/}
              {/*  </ListItemIcon>*/}
              {/*  <ListItemText primary="Jump to Music Festiverse" />*/}
              {/*  <img src="assets/images/roomThumbs/festiverse.jpg" alt={""} />*/}
              {/*</ListItem>*/}
              {/*<Divider />*/}

            </List>
          )}
          <List>
            <ListItem button onClick={() => window.open("./faq", "_blank")}>
              <ListItemIcon>
                <QuizIcon />
              </ListItemIcon>
              {/*<ListItemText primary="FAQ" secondary="blah" />*/}
              <ListItemText primary="FAQ"/>
            </ListItem>
          </List>
        </Box>
      </MuiDrawer>
    </>
  );
});

export default Drawer;
